@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

body {
    background-color: #080808; 
    /* background-color: transparent; */
    font-family: 'Segoe UI', sans-serif;
}

.title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
}

.subtitle {
    /* width: 952px;
    height: 44px; */
    flex-grow: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    /* color: #d9d9d9; */
}

.text {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    font-size: 16px;
    letter-spacing: normal;
}

.input-text {
    flex-grow: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
}

.button-text {
    flex-grow: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
}

@layer utilities {
    @variants responsive {
        /*Hide scrollbar for Chrome, Safari and Opera */
        .scrollbar::-webkit-scrollbar {
            /*padding: 5vh;*/
            /*display: none;*/
            background-color: transparent;
            width: 5px;
        }
        .scrollbar::-webkit-scrollbar-thumb {
            background-color: #4a5461;
            border-radius: 20px;
        }
        /* Hide scrollbar for IE, Edge and Firefox */
        .scrollbar {
            /*-ms-overflow-style: none;  !* IE and Edge *!*/
            /*scrollbar-width: none;  !* Firefox *!*/
        }
    }
}
